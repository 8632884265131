import React from "react";
import './TopPage.css';

function TopPage() {
    return (
        <div className="row pt-3">
            <div className="col-2 removable-column"></div>
            <div className="col-1 text-start d-flex align-items-center">
                <p className="text-decoration-underline" style={{ minWidth : '150px'}}>Par Nabil Hasnaoui</p>
            </div>
            <div className="col"></div>
            <div className="col-3 text-end ">
                <p className="vertical-center" style={{ minWidth : '100px'}}>Finissant au
                    <a href="https://claurendeau.qc.ca/" target="_blank" rel="noopener noreferrer">
                        <img src="/images/Andre_Laurendeau.png" alt="André-Laurendeau"/>
                    </a>
                </p>
            </div>
            <div className="col-2"></div>
        </div>
    );
}

export default TopPage;
