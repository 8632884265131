import {FaReact, FaBootstrap, FaJava, FaLock, FaGlobe, FaDatabase} from 'react-icons/fa';
import { SiSpringboot, SiPostgresql } from 'react-icons/si';

const disabled = "disabled";

export const descriptionGestionStages = (
    <p>
        Ce projet consiste en la conception et le développement d’une application web transactionnelle
        sécurisée pour la gestion centralisée des stages. Utilisant des technologies modernes telles que
        les <span className="text-decoration-underline">APIs RESTful</span> et une <span className="text-decoration-underline">
        architecture MVC</span> (Model-View-Controller), la plateforme vise à automatiser et à optimiser les processus
        administratifs, tout en respectant les normes de sécurité applicative.
        <br/><br/>
        L’application implémente <span className="text-decoration-underline">Spring Boot Security</span> pour garantir
        la protection des données sensibles et l'authentification des utilisateurs. Les <span className="text-decoration-underline">
        JWT tokens </span> (JSON Web Tokens) sont utilisés pour l'authentification et l'autorisation sécurisées des
        utilisateurs. Grâce à une <span className="text-decoration-underline">gestion fine des rôles et des
        autorisations</span>, chaque utilisateur (étudiant, tuteur, entreprise)
        a un <span className="text-decoration-underline"> accès spécifique en fonction de son rôle</span>, assurant ainsi une
        séparation claire des responsabilités et une sécurité renforcée. Elle offre également une interface intuitive et
        des fonctionnalités avancées, notamment la gestion des rôles, la <span className="text-decoration-underline">
        traçabilité des transactions</span> et la collaboration entre étudiants, tuteurs et entreprises.
        <br/><br/>
        Ce projet a été exécuté avec la <span className="text-decoration-underline">méthodologie Agile Scrum</span> en
        collaboration avec une <span className="text-decoration-underline">équipe de 5 développeurs.</span>
    </p>
);

export const descriptionGestionTaches = (
    <p>
        Ce projet consiste à développer une application de gestion de tâches utilisant React.js, Bootstrap, Java, la
        base de données H2 et Spring Boot. Le backend suit l'architecture MVC pour un code maintenable et utilise le
        développement piloté par les tests (TDD) avec JUnit, Mockito et MockMvc pour garantir la fiabilité. Le contrôle
        des versions est géré sur GitHub avec des branches principales pour les versions stables et des branches
        spécifiques aux nouvelles fonctionnalités. Lombok est utilisé pour simplifier le code en réduisant le code
        répétitif. <br/><br/>
        L'application offre une interface utilisateur intuitive et fluide pour une gestion des tâches efficace.

    </p>
);

export const descriptionGestionBibliotheque = (
    <p>
        Ce projet consiste à développer une application web de gestion de bibliothèque utilisant React.js pour une
        interface dynamique et fluide. Le back-end sera construit avec Spring Boot et Java, en appliquant l'architecture
        MVC pour une séparation claire des préoccupations. L'application suivra la méthodologie Test Driven Development
        (TDD), en utilisant JUnit, Mockito et MockMvc pour garantir la fiabilité du code. Les données seront stockées
        dans une base de données H2. <br/><br/>
        Elle permettra aux utilisateurs de gérer les livres, les emprunts et les utilisateurs,
        tout en offrant une interface simple et intuitive pour la recherche et la gestion des ressources.
    </p>
);

export const descriptionPremierSiteWebPortfolio = (
    <p>
        Ce projet vise à concevoir et développer un site portfolio moderne et interactif pour mettre en valeur des
        réalisations professionnelles et académiques. En exploitant des technologies modernes telles que React.js,
        Bootstrap, et une architecture modulaire, le site permettra de présenter des projets de manière visuellement
        attrayante et organisée.
        <br/><br/>
        Accessible via un navigateur, il offrira une navigation fluide et intuitive, avec des
        sections personnalisables pour les compétences, les expériences, les projets et les témoignages.
    </p>
);

const TECHNOLOGIES = {
    React: { icon: <FaReact />, title: "React.js - Framework JavaScript" },
    Bootstrap: { icon: <FaBootstrap />, title: "Bootstrap - Framework CSS" },
    Java: { icon: <FaJava />, title: "Java - Langage de programmation" },
    PostgreSQL: { icon: <SiPostgresql />, title: "PostgreSQL - Base de données relationnelle" },
    SpringBoot: { icon: <SiSpringboot />, title: "Spring Boot - Framework Java pour le backend" },
    Security: { icon: <FaLock />, title: "Sécurité - Authentification et Autorisation" },
    internationalization: { icon: <FaGlobe />, title: "Internationalization - Gestion des langues" },
    Database: { icon: <FaDatabase />, title: "Base de données H2 pour le stockage des données" },
    Email: { icon: <div className="fas fa-envelope" />, title: "Envoi de courriels" }
};


export const projects = {
    "projectGestionStages": {
        title: "Application web transactionnelle sécurisée de gestion de stages.",
        image: "/images/projects/2024/2024_projetGestionStages.png",
        description: descriptionGestionStages,
        lienGithub: "https://github.com/PDG07/Plateforme-gestion-stages",
        GithubPage: disabled,
        technologies: [
            TECHNOLOGIES.React,
            TECHNOLOGIES.Bootstrap,
            TECHNOLOGIES.Java,
            TECHNOLOGIES.PostgreSQL,
            TECHNOLOGIES.SpringBoot,
            TECHNOLOGIES.Security,
            TECHNOLOGIES.internationalization
        ],
    },
    "projectGestionTaches": {
        title: <p>Application de gestion de <br/>tâches.</p>,
        image: "/images/projects/2024/2024_projetGestionTaches.png",
        description: descriptionGestionTaches,
        lienGithub: "https://github.com/PDG07/gestionnaireTaches",
        GithubPage: disabled,
        technologies: [
            TECHNOLOGIES.React,
            TECHNOLOGIES.Bootstrap,
            TECHNOLOGIES.Java,
            TECHNOLOGIES.Database,
            TECHNOLOGIES.SpringBoot
        ],
    },
    "projectGestionBibliotheque": {
        title: "Application de gestion de bibliothèque.",
        image: "/images/projects/2024/2024_projetGestionBibliotheque.png",
        description: descriptionGestionBibliotheque,
        lienGithub: "https://github.com/PDG07/Plateforme-gestion-bibliotheque",
        GithubPage: disabled,
        technologies: [
            TECHNOLOGIES.React,
            TECHNOLOGIES.Java,
            TECHNOLOGIES.Database,
            TECHNOLOGIES.SpringBoot
        ],
    },
    "projectFirstPortfolioWebsite": {
        title: "Mon premier site web portfolio.",
        image: "/images/projects/2024/2024_projetSiteWebPersonnel.png",
        description: descriptionPremierSiteWebPortfolio,
        lienGithub: disabled,
        GithubPage: disabled,
        technologies: [
            TECHNOLOGIES.React,
            TECHNOLOGIES.Bootstrap,
            TECHNOLOGIES.internationalization,
            TECHNOLOGIES.Email
        ],
    },
};
