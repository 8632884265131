import React, {useEffect} from "react";
import './WelcomePage.css';
import { useNavigate } from "react-router-dom";

function WelcomePage() {
    const navigate = useNavigate();

    const handleButtonClick = () => {
        navigate("/contact");
    };

    const handleMouseMove = (e) => {
        if (window.innerWidth <= 768) {
            return;
        }
        const images = document.querySelectorAll('.photo');
        images.forEach((image) => {
            const rect = image.getBoundingClientRect();
            const offsetX = (e.clientX - rect.left) / rect.width;
            const offsetY = (e.clientY - rect.top) / rect.height;

            image.style.transform = `translate(${offsetX * -10}px, ${offsetY * -10}px)`;
        });
    };

    useEffect(() => {
        window.addEventListener('mousemove', handleMouseMove);
        return () => {
            window.removeEventListener('mousemove', handleMouseMove);
        };
    }, []);

    return (
        <div>
            <div className="row pt-3">
                <div className="col-2 removable-column"></div>
                <div className="col col-xl-5 text-start mt-5">
                    <h1>
                        <span className="font-islands">Bienvenue </span>
                        <span className="font-inter">
                        dans mon <br/> Portfolio.
                        <img id="icon-terminal" className="mx-2 pb-2" src="/images/Code.svg" alt="Terminal"/>
                    </span>
                    </h1>
                    <br/>
                    <p style={{maxWidth: '500px'}}>
                        Je suis un développeur logiciel passionné & minucieux, déterminé à devenir un atout précieux
                        pour vos
                        projets. Ce site web présente mes compétences ainsi que mes connaissances en développement
                        logiciel et web.
                        Jetez-y un œil!
                        <br/> <br/>
                        Je serais ravis de pouvoir contribuer au succes de votre entreprise!
                    </p>
                    <button className="btn-custom px-5 py-1" onClick={handleButtonClick}>Écrivez-moi!</button>
                    <img className="image-sparkle pb-3" src="/images/Sparkle.svg" alt="Sparkle"/>
                </div>
                <div className="col-12 col-md-5 photo-container">
                    <img className="photo photo-1" src="/images/ImageDeigner.png" alt="Designer"/>
                    <img className="photo photo-2" src="/images/Ordinateur_portable_biscuit.png"
                         alt="Ordinateur portable avec biscuit"/>
                    <img className="photo photo-3" src="/images/Image_chaise.png" alt="Chaise"/>
                </div>
                <div className="col-2"></div>
            </div>

            <div className="row row-icon-next">
                <img className="image-icon_next" src="/images/Caret_Square_Down.svg" alt="Icon_Next"/>
            </div>
        </div>
    );
}

export default WelcomePage;
