import React from "react";
import './RecommendationPage.css';

function RecommendationPage() {
    return (
        <div>
            <div className="row">
                <div className="col-2 removable-column"></div>
                <div className="col text-start">
                    <h2>Un petit mot de mon professeur avant de partir!</h2>
                </div>
            </div>
            <div className="row mt-3">
                <div className="recommendation-container" style={{position: "relative"}}>
                    <img className="image-recommendation my-3" src="/images/Recommendation_Francois-Lacourciere.png"
                         alt="Lettre de recommandation"/>
                    <img className="FireworksTR-img" src="/images/Fireworks_Half_TopRight.svg" alt="Fireworks TR"/>
                </div>
            </div>

        </div>
    );
}

export default RecommendationPage;
