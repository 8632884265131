import React, { useState } from "react";
import './ProjectsPage.css';
import { projects } from './projectsData';

function ProjectsPage() {
    const [currentProjectIndex, setCurrentProjectIndex] = useState(0); // Suivi de l'index du projet courant

    const handleNextProject = () => {
        if (currentProjectIndex < Object.keys(projects).length - 1) {
            setCurrentProjectIndex(currentProjectIndex + 1);
        }
    };

    const handlePreviousProject = () => {
        if (currentProjectIndex > 0) {
            setCurrentProjectIndex(currentProjectIndex - 1);
        }
    };

    const currentProject = projects[Object.keys(projects)[currentProjectIndex]];

    return (
        <div>
            <div className="row">
                <div className="col-2 removable-column"></div>
                <div className="col text-start">
                    <h2>Projets Applicatifs <img className="pb-2 image-Cup_Shining" src="/images/Trophy.svg" alt="Trophy" /></h2>
                    <br />
                    <p>
                        Cette section présente une sélection de projets réalisés dans le cadre de mes études et de mes
                        initiatives personnelles. Chaque projet met en avant mes compétences en conception,
                        développement et déploiement d’applications web et logicielles, en utilisant des technologies
                        modernes et des méthodologies agiles.
                    </p>
                </div>
                <div className="col-2 removable-column"></div>
            </div>

            <div className="projects-carousel">
                <PortfolioProject
                    imageSrc={currentProject.image}
                    title={currentProject.title}
                    description={currentProject.description}
                    lienGithub={currentProject.lienGithub}
                    technologies={currentProject.technologies}
                />
            </div>

            <div className="progress-bar-container endPage-margin">
                {/* Cacher le bouton "Previous" si on est à la première barre */}
                {currentProjectIndex > 0 && (
                    <button onClick={handlePreviousProject} className="prev-btn mx-3">{"<"}</button>
                )}

                {Object.keys(projects).map((_, index) => (
                    <div
                        key={index}
                        className={`progress-bar ${index === currentProjectIndex ? "filled" : "empty"}`}
                    />
                ))}

                {/* Cacher le bouton "Next" si on est à la dernière barre */}
                {currentProjectIndex < Object.keys(projects).length - 1 && (
                    <button onClick={handleNextProject} className="next-btn mx-3">{">"}</button>
                )}
            </div>
        </div>
    );
}

function PortfolioProject({ imageSrc, title, description, lienGithub, technologies }) {
    return (
        <div className="row my-5">
            <div className="col-2"></div>
            <div className="col-12 col-lg-4 d-flex align-items-center">
                <img className="images-projects" src={imageSrc} alt={title} />
            </div>
            <div className="col-1 custom-card-transition-text"></div>
            <div className="col-12 col-lg-3 custom-card-text text-start" style={{position: "relative"}}>
                <h3 className="mt-4">{title}</h3>
                <br/>
                <p className="mb-4">{description}</p>
                <div className="technologies-list my-3">
                    {Array.isArray(technologies) && technologies.length > 0 ? (
                        technologies.map((tech, index) => (
                            <div key={index} className="technology-item icon" data-toggle="tooltip" data-placement="top"
                                 title={tech.title}>
                                <div className="tech-icon">{tech.icon}</div>
                            </div>
                        ))
                    ) : ""}
                </div>
                {lienGithub !== "disabled" && (
                    <a href={lienGithub} target="_blank" rel="noopener noreferrer" className="custom-btn-github mb-3">
                        Voir code source
                        <i className="fab fa-github"></i>
                    </a>
                )}
                {lienGithub !== "disabled" && (
                    <img className="Fireworks-img" src="/images/Fireworks_Half_BottomRight.svg" alt="Fireworks"/>
                )}
            </div>
            <div className="col"></div>
        </div>
    );
}


export default ProjectsPage;
