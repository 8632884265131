import React from "react";
import './DividerPage.css';

const DividerPage = () => {
    return <div className="m-2">
        <img className="responsive-img mx-5 img-hidden" src="/images/Line_horizontal.png" alt="Line_horizontal"/>
        <img className="responsive-img" src="/images/Ribbon_Loop.svg" alt="Ruban"/>
        <img className="responsive-img mx-5 img-hidden" src="/images/Line_horizontal.png" alt="Line_horizontal"/>
    </div>;
}

export default DividerPage;