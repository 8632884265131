import React from "react";
import './LandingPage.css';
import TopPage from "../topPage/TopPage";
import WelcomePage from "../welcomePage/WelcomePage";
import SkillsPage from "../skillsPage/SkillsPage";
import ProjectsPage from "../projectsPage/ProjectsPage";
import AboutPage from "../aboutPage/AboutPage";
import RecommendationPage from "../recommendationPage/RecommendationPage";
import Footer from "../footer/Footer";

function LandingPage() {
    return (
        <div>
            <TopPage/>
            <WelcomePage/>
            <SkillsPage/>
            <ProjectsPage/>
            <AboutPage/>
            <RecommendationPage/>
            <Footer/>
        </div>
    );
}

export default LandingPage;
