import React from "react";
import './SkillsPage.css';
import DividerPage from "../features/dividerPage/DividerPage";

function SkillsPage() {
    return (
        <div>
            <div className="row mt-5">
                {/* Rectangle beige en arrière-plan */}
                <div className="background-rectangle"></div>

                <div className="col-2 removable-column"></div>
                <div className="col text-start" style={{position: "relative"}}>
                    <img className="Icon_excitement" src="/images/Excitement.svg" alt="Icon_excitement"/>
                    <h2>Vue d’ensemble de mes competences en <br/> développement logiciel et web.
                        <img className="pb-2 Message_Programming" src="/images/Message_Programming.svg"
                             alt="Icon_message_code"/></h2>
                    <p>
                        À travers mon parcours collégial, j’ai eu l’opportunité d’acquérir une solide expérience dans le
                        domaine du développement logiciel & web. Cela m’a permis de maîtriser les technologies, les
                        outils, et les bonnes pratiques nécessaires pour concevoir et mettre en œuvre des solutions
                        performantes et évolutives. Vous retrouverez ci-dessous une synthèse de mes compétences
                        techniques,
                        organisées par catégories.
                    </p>
                </div>
                <div className="col-2"></div>
            </div>

            {/* Frameworks, Languages, balisage, échange de donnée */}
            <div className="row mt-4">
                <div className="col col-lg-2 skills-overview-flat removable-column removable-column-ipadSize"></div>
                <div className="col-5 col-lg-2 text-start skills-overview-flat hovered-skill">
                    <div className="d-flex align-items-center ">
                        <img className="custom-image" src="/images/Element_1.svg" alt="Icon_framework"/>
                        <h5 className="pt-2 ms-2">Frameworks & <br/> Bibliothèques</h5>
                    </div>
                    <ul>
                        <li>Angular</li>
                        <li>React.js</li>
                        <li>Bootstrap</li>
                        <li>Spring Boot</li>
                        <li>Hibernate</li>
                        <li>JDBC</li>
                    </ul>
                </div>

                <div className="col-5 col-lg-2 text-start skills-overview-flat hovered-skill">
                    <div className="d-flex align-items-center ">
                        <img className="custom-image" src="/images/Document_Code.svg"
                             alt="Icon_language_programmation"/>
                        <h5 className="pt-2 ms-2">Langages de <br/> programmation</h5>
                    </div>
                    <ul>
                        <li>Java SE</li>
                        <li>C#</li>
                        <li>HTML5</li>
                        <li>CSS & SCSS</li>
                        <li>TypeScript</li>
                        <li>Python</li>
                    </ul>
                </div>

                <div className="col-2 d-md-none skills-overview-flat removable-column removable-column-ipadSize"></div>

                <div className="col-5 col-lg-2 text-start skills-overview-flat hovered-skill">
                    <div className="d-flex align-items-center ">
                        <img className="custom-image" src="/images/Ruler.svg" alt="Icon_balisage"/>
                        {/*<h5 className="pt-2 ms-2">Langages de balisage <br/> et de style</h5>*/}
                        <h5 className="pt-2 ms-2">Principes de développement</h5>
                    </div>
                    <ul>
                        {/*<li>HTML5</li>
                        <li>CSS & SCSS</li>*/}
                        <li>DRY (Don't Repeat Yourself)</li>
                        <li>KISS (Keep It Simple, Stupid)</li>
                        <li>YAGNI (You Aren't Gonna Need It)</li>
                        <li>ACID (Atomicity, Consistency, Isolation, Durability)</li>
                    </ul>
                </div>

                <div className="col-5 col-lg-2 text-start skills-overview-first hovered-skill">
                    <div className="d-flex align-items-center ">
                        <img className="custom-image" src="/images/Document_Code.svg" alt="Icon_language_programmation"/>
                        <h5 className="pt-2 ms-2">Langages de <br/> requêtes & d'échange <br/> de données</h5>
                    </div>
                    <ul>
                        <li>SQL</li>
                        <li>JSON</li>
                        <li>XML</li>
                        <li>YAML</li>
                        <li>GraphQL (en cours)</li>
                        <li>Docker</li>
                    </ul>
                </div>

                <div className="col"></div>
            </div>

            {/* Outils de developement, Conception/architecture logicielle, Protocoles, Méthodologies */}
            <div className="row">
                <div className="col col-lg-2 skills-overview-flat removable-column removable-column-ipadSize"></div>
                <div className="col-5 col-lg-2 text-start skills-overview-flat hovered-skill">
                    <div className="d-flex align-items-center ">
                        <img className="custom-image" src="/images/Data_2.svg" alt="Icon_outils_developpement"/>
                        <h5 className="pt-2 ms-2">Outils de <br/> developement</h5>
                    </div>
                    <ul>
                        <li>IntelliJ IDEA</li>
                        <li>Visual Studio Code</li>
                        <li>Git / GitHub</li>
                        <li>Maven</li>
                        <li>Postman API</li>
                        <li>Figma</li>
                    </ul>
                </div>

                <div className="col-5 col-lg-2 text-start skills-overview-flat hovered-skill ">
                    <div className="d-flex align-items-center ">
                        <img className="custom-image" src="/images/Brush.svg" alt="Icon_conception_logicielle"/>
                        <h5 className="pt-2 ms-2">Conception & <br/> architecture logicielle.</h5>
                    </div>
                    <ul>
                        <li>Architecture MVC</li>
                        <li>Approche Test-Driven Development (TDD)</li>
                        <li>Programmation Orienté Objet</li>
                        <li>Programmation Async</li>
                        <li>UML</li>
                        <li>Modélisation logicielle</li>
                        <li> Data structures & Algorithms</li>
                    </ul>
                </div>

                <div className="col-2 d-md-none skills-overview-flat removable-column removable-column-ipadSize"></div>

                <div className="col-5 col-lg-2 text-start skills-overview-flat hovered-skill">
                    <div className="d-flex align-items-center ">
                        <img className="custom-image" src="/images/Copy_Success.svg" alt="Icon_protocole"/>
                        <h5 className="pt-2 ms-2">Protocoles et <br/> normes de services <br/> Web</h5>
                    </div>
                    <ul>
                        <li>Restful API</li>
                        <li>JSON Web Tokens (JWT)</li>
                        <li>Communications API sécurisée via tokens</li>
                        <li>Contrôle d'accès basé sur les rôles (RBAC)</li>
                    </ul>
                </div>

                <div className="col-5 col-lg-2 text-start skills-overview hovered-skill" style={{position : "relative"}}>
                    <div className="d-flex align-items-center ">
                        <img className="custom-image" src="/images/Glass.svg" alt="Icon_methodologie"/>
                        <h5 className="pt-2 ms-2"> Méthodologies et <br/> gestion de projet</h5>
                    </div>
                    <ul>
                        <li>Scrum / Agile / Waterfall</li>
                        <li>Jira</li>
                    </ul>

                    <img className="Comet-image" src="/images/Comet.svg" alt="Comet"/>
                </div>

                <div className="col"></div>
            </div>
            <DividerPage/>
        </div>
    );
}

export default SkillsPage;
