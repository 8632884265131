import React from "react";
import './ContactPage.css';
import {useEffect, useState} from "react";
import { useForm, ValidationError } from '@formspree/react';
import MySwal from "sweetalert2";
import { useNavigate } from "react-router-dom";

function ContactPage() {
    const [phone, setPhone] = useState('');
    const [state, handleSubmit] = useForm("movqnnol");
    const navigate = useNavigate();

    const handlePreviousPage = () => {
        navigate("/");
    };

    const handlePhoneChange = (e) => {
        let value = e.target.value;

        value = value.replace(/\D/g, '');

        if (value.length > 10) {
            value = value.slice(0, 10);
        }

        if (value.length <= 3) {
            value = `(${value}`;
        } else if (value.length <= 6) {
            value = `(${value.slice(0, 3)}) ${value.slice(3)}`;
        } else {
            value = `(${value.slice(0, 3)}) ${value.slice(3, 6)}-${value.slice(6, 10)}`;
        }

        setPhone(value);
    };

    const handlePhoneBlur = () => {
        if (phone.length === 1) {
            setPhone('');
        }
    };

    useEffect(() => {
        if (state.succeeded) {
            MySwal.fire({
                title: "Merci !",
                text: "Votre message a été envoyé avec succès.",
                icon: 'success',
                confirmButtonText: "OK",
            }).then(() => {
                setPhone('');
            });
        }
    }, [state.succeeded]);

    return (
        <div className="d-flex flex-column pt-2">
            <button onClick={handlePreviousPage} className="prev-page m-4">{"<"}</button>
            <div className="row">
                <div className="col-1"></div>
                <div className="col">
                    <h1 className="mx-5 text-start animate-text">Nabil <span style={{color: "#2E67F8"}}>.</span></h1>
                </div>
            </div>

            <div className="row">
                <div className="col-12 col-lg-8">
                    <div className="form-container">
                        <form className="form" onSubmit={handleSubmit}>
                            <h2 className="letter-spacing maven-font text-start" style={{color: "#71E0FF"}}>Travaillons
                                ensemble</h2>

                            {/* Première ligne : Nom et Prénom */}
                            <div className="form-row mb-4 mt-3">
                                <input
                                    type="text"
                                    className="form-input"
                                    placeholder="Nom"
                                    required
                                    name="nom"
                                />
                                <input
                                    type="text"
                                    className="form-input"
                                    placeholder="Prénom"
                                    required
                                    name="prenom"
                                />
                            </div>

                            {/* Deuxième ligne : Adresse email */}
                            <div className="form-row mb-4">
                                <input
                                    type="email"
                                    className="form-input"
                                    placeholder="Adresse e-mail"
                                    required
                                    name="email"
                                />
                                <input
                                    type="text"
                                    className="form-input"
                                    placeholder="Numéro de téléphone"
                                    value={phone}
                                    onBlur={handlePhoneBlur}
                                    onChange={handlePhoneChange}
                                    maxLength="14"
                                    name="phone"
                                />
                            </div>

                            {/* Troisième ligne : Zone de texte */}
                            <div className="form-row">
                                <textarea
                                    className="form-input message"
                                    placeholder="Tapez votre message ici"
                                    required
                                    name="message"
                                />
                            </div>

                            {/* Quatrième ligne : Bouton send message */}
                            <div className="form-row justify-content-center mt-3">
                                <button type="submit" className="send-button" disabled={state.submitting}>
                                    {state.submitting ? "Envoi du message..." : "Envoyer le message"}
                                </button>
                            </div>
                        </form>

                        {/* Affichage des erreurs de validation */}
                        <ValidationError
                            prefix="Email"
                            field="email"
                            errors={state.errors}
                        />
                        <ValidationError
                            prefix="Message"
                            field="message"
                            errors={state.errors}
                        />
                    </div>
                </div>
                <div className="col-12 col-lg-4">
                    <div
                        className="col-lg-5 d-flex justify-content-center align-items-center order-2 order-lg-1 animate-img">
                        <img src="/images/imageWorker.png" alt="KnowledgeRatioByHeart"/>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ContactPage;
