import React from "react";
import './AboutPage.css';
import DividerPage from "../features/dividerPage/DividerPage";

function AboutPage() {
    return (
        <div>
            <div className="row">
                <div className="col-2 removable-column"></div>
                <div className="col text-start">
                    <h2 className="">A propos de moi! <img className="pb-4 image-Man_Accomplished"
                                                           src="/images/Being_Vip.svg"
                                                           alt="Man_Accomplished"/></h2>
                    <br/>
                    <p>
                        Je me considère comme une personne dotée de multiples qualités professionnelles et personnelles
                        qui se reflètent concrètement dans les tâches que j'entreprends. Mon approche méthodique à la
                        résolution de problèmes fonctionnels et techniques, ma rigueur organisationnelle, ainsi que mes
                        compétences en communication et en rédaction de documentation, me permettent d’analyser une
                        demande, de la traduire en étapes logiques et structurées, et de m’adapter à divers environnements
                        techniques et méthodologies de travail. J'applique également les meilleures pratiques de
                        l’industrie du génie logiciel, notamment l’utilisation de patrons de conception, afin de produire
                        un code maintenable et de qualité.
                        <br/><br/>
                        Par ailleurs, je possède une grande facilité et rapidité d’apprentissage, une organisation solide,
                        une autonomie prononcée, un esprit d’analyse et de synthèse, une forte capacité d’adaptation, ainsi
                        qu’un sens aigu des responsabilités.
                    </p>
                </div>
                <div className="col-2 removable-column"></div>
            </div>
            <div className="row row-overlay my-5">
                <div className="text-overlay d-flex justify-content-center align-items-center text-start">
                    <p className="text px-3 my-3">
                        Je porte un intérêt particulier à des industries telles que le secteur financier, le secteur
                        public et gouvernemental, le domaine de la santé, ainsi que la consultation. Ces
                        environnements dynamiques me motivent à appliquer mes compétences techniques pour relever
                        des défis variés et contribuer à des projets innovants et à fort impact, que ce soit en
                        matière de modernisation technologique, d'amélioration des services ou d'optimisation des processus!
                    </p>
                </div>

                <img
                    className="image-About_BG"
                    src="/images/About_BG.png"
                    alt="About_BG"
                />
            </div>
        <DividerPage/>
        </div>
    );
}

export default AboutPage;
