import './App.css';
import LandingPage from "./component/landingPage/LandingPage";
import {Route, BrowserRouter as Router, Routes} from "react-router-dom";
import ContactPage from "./component/contactPage/ContactPage";

function App() {
  return (
      <div className="App">
          <Router>
              <div className="container-fluid">
                  <Routes>
                      <Route path="/contact" element={<ContactPage/>}/>
                      <Route path="/" element={<LandingPage/>}/>
                      <Route path="*" element={<LandingPage/>}/>
                  </Routes>
              </div>
          </Router>
      </div>
  );
}

export default App;
