import React, { useState } from "react";
import "./Footer.css";

function Footer() {
    const [showTooltip, setShowTooltip] = useState(false);

    const copyToClipboard = () => {
        const email = "nabil.hasnaoui.it@gmail.com";
        navigator.clipboard
            .writeText(email)
            .then(() => {
                setShowTooltip(true);
                setTimeout(() => setShowTooltip(false), 2000); // Cache la bulle après 2 secondes
            })
            .catch((err) => {
                console.error("Erreur lors de la copie :", err);
            });
    };

    return (
        <div>
            <div className="row position-relative">
                <div className="col text-end">
                    <img className="custom-Flag_Thanks" src="/images/Flag_Thanks.svg" alt="Flag_Thanks" />
                    <div className="horizontal-bar"></div>
                </div>
            </div>
            <div className="row">
                <div className="col-2 removable-column"></div>
                <div className="col-2" style={{minWidth : '150px'}}>Portfolio 2024 v2.0</div>
                <div className="col removable-column"></div>
                <div className="col-2">
                    <a
                        href="https://www.linkedin.com/in/hnabil07/"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="linkedin-link"
                    >
                        <i className="fab fa-linkedin"></i><span style={{minWidth : '130px'}}>Profil LinkedIn</span>
                    </a>
                    <br/>
                    <div className="email-copy-container">
                        <p
                            onClick={copyToClipboard}
                            className="email-text"
                            title="Cliquez pour copier l'adresse email"
                        >
                            nabil.hasnaoui.it@gmail.com
                        </p>
                        {showTooltip && <div className="tooltip">Email copié !</div>}
                    </div>
                </div>
                <div className="col-2 removable-column"></div>
            </div>
        </div>
    );
}

export default Footer;
